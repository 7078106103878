
  select {
    -webkit-appearance:none;
    -moz-appearance:none;
    -ms-appearance:none;
    appearance:none;
    outline:0;
    box-shadow:none;
    background: #fff;
    background-image: none;
    flex: 1;
    padding: 0.5em;
    color:#5c6664;
    cursor:pointer;
    font-size: 1em;
    font-family: 'Open Sans', sans-serif;
    border: "1px solid black",
  }
  select::-ms-expand {
    display: none;
  }
  .select {
    margin-top: 12px;
    position: relative;
    display: flex;
    width: 33em;
    height: 3em;
    line-height: 3;
    background: #fff;
    overflow: hidden;
    margin-left: 417px;
    border-radius: 2px;
    border: "1px solid black",
  }
  .select::after {
    content: '\25BC';
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 1em;
    background: #fff;
    cursor:pointer;
    pointer-events:none;
    transition:.25s all ease;
  }

  .select:hover::after {
    color: #23b499;
  }
  
 