.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


label {
  font-size: 15px;
}

#frombg {
  background-color: whitesmoke;
  color: black;

  padding: 20px;
  align-items: center;
}
th {
  font-size: 15px;
  padding: 10px;
  margin: 0px;
}

#post {
  font-size: 15px;
  padding: 5px;
}

.App-link {
  color: #61dafb;
}
#Viewservicestable {
  display: block;
}

.row {
  display: flex;
  margin: 10px;
}

.column {
  flex: 50%;
}

.not-container{
  width:450px;
  height:400px;
  margin:0 auto;
  padding:50px;
  background-color:#fafafa;
  border:1px solid black;
}

.issueform{
  padding:10px 5px;
}
.issue-dropdown-box{
  display: block;
  margin-left: -5px;
}
.issue{
  font-size: 20px;
  margin-right: 40px;
}
.issue-dropdown{
  width:50%;
  padding:10px 10px;
  display: inline-block;
  outline:0;
  border:none;
  cursor: pointer;
  font-size: 15px;
}
.remarksbox{
  display: block;
  margin-top: 50px;
  margin-left: 27px;
  margin-bottom: 50px;
}

.remarks{
  font-size: 17px;
  margin-right: -35px;
}

.issue-submit{
  width: 80px;
  padding: 10px;
  border-radius: 5px;
  outline: 0;
  border:none;
  cursor: pointer;
  background-color: black;
  color: white;
  text-align: center;
}




